import React from "react"
import Inscription from "../components/inscription/inscription"
import Seo from "../components/seo"

export default function InscriptionPage() {
  return (
    <div>
      <Seo title="Inscription" />
      <Inscription />
    </div>
  )
}