import React from "react"
import { Space, Typography } from "antd"

const { Text, Link } = Typography
export default function InscriptionMeta() {
  
  return (
    <Space direction={"vertical"} align={"center"}
           style={{ textAlign: "center", width: "100%" }}>
      <Text>
        E-mails: h.douagui@gmail.com / info@congresaaic2021.com
      </Text>
      <Text>
        Téléphone: 023113250 / mobile: 0661529015
      </Text>
      <Text>
        Facebook:<Link href={"https://facebook.com/saaicdz"}
                       target="_blank"> @saaicdz</Link>
      </Text>
    </Space>
  )
}