import { useLazyQuery } from "@apollo/client"

import { LOGIN } from "../apollo/queries"

export default function useLogin() {
  
  const [login, { loading, error, data }] = useLazyQuery(LOGIN)
  
  function loginAttendee(email) {
    login({ variables: { email } })
  }
  
  return {
    loginAttendee,
    loading,
    error,
    data: data ? data.loginAttendee : undefined
  }
}