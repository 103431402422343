import { gql } from "@apollo/client"

const SUBSCRIBE = gql`
    mutation createAttendee($attendee:CreateAttendee!){
        createAttendee(attendee:$attendee){
            _id
            infos
        }
    }
`

export { SUBSCRIBE }