import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import { Card, Col, Row } from "antd"
import InscriptionCover from "./inscriptionCover"
import InscriptionForm from "./inscriptionForm"
import InscriptionMeta from "./inscriptionMeta"

const { Meta } = Card
export default function Inscription() {
  const image = useStaticQuery(
    graphql`
        query {
            desktop: file(relativePath: { eq: "background-saaic.jpg" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `
  )
  const imageData = image.desktop.childImageSharp.fluid
  
  return (
    <div style={{ display: "grid" }}>
      < BackgroundImage
        fluid={imageData}
        style={{ gridArea: "1/1", height: "100vh", filter: "blur(8px)" }}
      />
      <Row justify={"center"}
           style={{ height: "100%", width: "100%", gridArea: "1/1" }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 14 }}
        >
          <Card
            style={{
              backgroundColor: "rgba(255,255,255,0.9)",
              borderStyle: "none",
              height: "100%"
            }}
            cover={<InscriptionCover />}
          >
            <InscriptionForm style={{ marginBottom: 20 }} />
            <Meta description={<InscriptionMeta />} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}