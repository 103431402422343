const countries = [
  "Afghanistan",
  "Afrique du Sud",
  "Albanie",
  "Algérie",
  "Allemagne",
  "Andorre",
  "Angola",
  "Antigua-et-Barbuda",
  "Arabie saoudite",
  "Argentine",
  "Arménie",
  "Australie",
  "Autriche",
  "Azerbaïdjan",
  "Bahamas",
  "Bahreïn",
  "Bangladesh",
  "Barbade",
  "Belgique",
  "Belize",
  "Bhoutan",
  "Bolivie",
  "Bosnie-Herzégovine",
  "Botswana",
  "Brunéi Darussalam",
  "Brésil",
  "Bulgarie",
  "Burkina Faso",
  "Burundi",
  "Bélarus",
  "Bénin",
  "Cabo Verde",
  "Cambodge",
  "Cameroun",
  "Canada",
  "Chili",
  "Chine",
  "Chypre",
  "Colombie",
  "Comores",
  "Congo",
  "Costa Rica",
  "Croatie",
  "Cuba",
  "Côte d'Ivoire",
  "Danemark",
  "Djibouti",
  "Dominique",
  "El Salvador",
  "Espagne",
  "Estonie",
  "Eswatini",
  "Fidji",
  "Finlande",
  "France",
  "Fédération de Russie",
  "Gabon",
  "Gambie",
  "Ghana",
  "Grenade",
  "Grèce",
  "Guatemala",
  "Guinée",
  "Guinée équatoriale",
  "Guinée-Bissau",
  "Guyana",
  "Géorgie",
  "Haïti",
  "Honduras",
  "Hongrie",
  "Inde",
  "Indonésie",
  "Iran",
  "Iraq",
  "Irlande",
  "Islande",
  "Israël",
  "Italie",
  "Jamaïque",
  "Japon",
  "Jordanie",
  "Kazakhstan",
  "Kenya",
  "Kirghizistan",
  "Kiribati",
  "Koweït",
  "Lesotho",
  "Lettonie",
  "Liban",
  "Libye",
  "Libéria",
  "Lituanie",
  "Luxembourg",
  "Macédoine du Nord",
  "Madagascar",
  "Malaisie",
  "Malawi",
  "Maldives",
  "Mali",
  "Malte",
  "Maroc",
  "Maurice",
  "Mauritanie",
  "Mexique",
  "Micronésie",
  "Monaco",
  "Mongolie",
  "Monténégro",
  "Mozambique",
  "Myanmar",
  "Namibie",
  "Nauru",
  "Nicaragua",
  "Niger",
  "Nigéria",
  "Nioué",
  "Norvège",
  "Nouvelle-Zélande",
  "Népal",
  "Oman",
  "Ouganda",
  "Ouzbékistan",
  "Pakistan",
  "Palaos",
  "Panama",
  "Papouasie-Nouvelle-Guinée",
  "Paraguay",
  "Pays-Bas",
  "Philippines",
  "Pologne",
  "Portugal",
  "Pérou",
  "Qatar",
  "Roumanie",
  "Royaume-Uni",
  "Rwanda",
  "République arabe syrienne",
  "République centrafricaine",
  "République de Corée",
  "République de Moldova",
  "République dominicaine",
  "République démocratique du Congo",
  "République démocratique populaire lao",
  "République populaire démocratique de Corée",
  "République-Unie de Tanzanie",
  "Saint-Kitts-et-Nevis",
  "Saint-Marin",
  "Saint-Vincent-et-les Grenadines",
  "Sainte-Lucie",
  "Samoa",
  "Sao Tomé-et-Principe",
  "Serbie",
  "Seychelles",
  "Sierra Leone",
  "Singapour",
  "Slovaquie",
  "Slovénie",
  "Somalie",
  "Soudan",
  "Soudan du Sud",
  "Sri Lanka",
  "Suisse",
  "Suriname",
  "Suède",
  "Sénégal",
  "Tadjikistan",
  "Tchad",
  "Tchéquie",
  "Thaïlande",
  "Timor-Leste",
  "Togo",
  "Tokélaou",
  "Tonga",
  "Trinité-et-Tobago",
  "Tunisie",
  "Turkménistan",
  "Turquie",
  "Tuvalu",
  "Ukraine",
  "Uruguay",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Yémen",
  "Zambie",
  "Zimbabwe",
  "Îles Cook",
  "Îles Féroé",
  "Îles Marshall",
  "Îles Salomon",
  "Égypte",
  "Émirats arabes unis",
  "Équateur",
  "Érythrée",
  "États-Unis d'Amérique",
  "Éthiopie"
]

const dzStates=[
  "Adrar",
  "Aïn Defla",
  "Aïn Témouchent",
  "Alger",
  "Annaba",
  "Batna",
  "Béchar",
  "Béjaïa",
  "Béni Abbès",
  "Biskra",
  "Blida",
  "Bordj Baji Mokhtar",
  "Bordj Bou Arréridj",
  "Bouïra",
  "Boumerdès",
  "Chlef",
  "Constantine",
  "Djanet ",
  "Djelfa",
  "El Bayadh",
  "El M'ghair",
  "El Menia",
  "El Oued",
  "El Tarf",
  "Ghardaïa",
  "Guelma",
  "Illizi",
  "In Guezzam",
  "In Salah",
  "Jijel",
  "Khenchela",
  "Laghouat",
  "M'Sila",
  "Mascara",
  "Médéa",
  "Mila",
  "Mostaganem",
  "Naama",
  "Oran",
  "Ouargla",
  "Ouled Djellal",
  "Oum El Bouaghi",
  "Relizane",
  "Saïda",
  "Sétif",
  "Sidi Bel Abbès",
  "Skikda",
  "Souk Ahras",
  "Tamanghasset",
  "Tébessa",
  "Tiaret",
  "Timimoun",
  "Tindouf",
  "Tipasa",
  "Tissemsilt",
  "Tizi Ouzou",
  "Tlemcen",
  "Touggourt",
]
export { countries,dzStates }
