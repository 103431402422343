import { gql } from "@apollo/client"

const LIVE = gql`
    query live($_id:String!){
        live(_id:$_id)
    }
`
const LOGIN = gql`
    query login($email:String!){
        loginAttendee(email:$email){
            _id
            infos
            email
        }
    }
`

export { LIVE, LOGIN }