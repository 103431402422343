import { useMutation } from "@apollo/client"
import { SUBSCRIBE } from "../apollo/mutations"

export default function useSubscribe() {
  
  const [createAttendee, { loading, error, data }] = useMutation(SUBSCRIBE)
  
  function subscribe(data) {
    const infos = {
      ...data,
      specialite: data.specialite.length === 1 ? data.specialite[0] : data.specialite[1]
    }
    const attendee = {
      eventId: process.env.EVENT_ID,
      email: data.email,
      state: "subscribed",
      infos: JSON.stringify(infos)
    }
    createAttendee({ variables: { attendee } })
  }
  
  return { subscribe, loading, error, data }
}