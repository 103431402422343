import React, { useEffect, useState } from "react"
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Result,
  Row,
  Select,
  Spin
} from "antd"
import { formItemLayout } from "../../utils/formLayout"
import { specialite } from "../../utils/options"
import useSubscribe from "../../hooks/subscribe"
import { countries, dzStates } from "../../utils/countries"
import { navigate } from "gatsby-link"
import useLogin from "../../hooks/login"

export default function InscriptionForm() {
  const [inscriptionForm] = Form.useForm()
  const { subscribe, error, loading, data } = useSubscribe()
  const [isChecked, setIsChecked] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [attendee, setAttendee] = useState({ nom: "", prenom: "", email: "" })
  const [states, setStates] = useState([])
  const { data: loginData, loginAttendee } = useLogin()
  useEffect(() => {
    if (localStorage.getItem("userId")) {
      navigate("/congres")
    }
  }, [])
  
  
  useEffect(() => {
    if (loginData) {
      localStorage.setItem("userId", loginData._id)
      message.success("Bienvenu " + attendee.nom + " " + attendee.prenom, 2)
      navigate("/congres")
    }
  }, [loginData])
  
  function handleLogin(email) {
    loginAttendee(email)
  }
  
  useEffect(() => {
    if (error && error.message.includes("duplicate")) {
      Modal.error({
        title: "E-mail dupliqué",
        content: "L'adresse mail que vous avez saisi est déjà utilisé, veuillez choisir une autre",
        centered: true
      })
    }
  }, [error])
  
  useEffect(() => {
    if (data) {
      const infos = JSON.parse(data.createAttendee.infos)
      setAttendee({ nom: infos.nom, prenom: infos.prenom, email: infos.email })
      setIsVisible(true)
    } else {
      setAttendee({ nom: "", prenom: "", email: "" })
    }
  }, [data])
  
  function onChangeCountry(country) {
    let ville = ""
    if (country === "Algérie") {
      setStates(dzStates)
      ville = dzStates[0]
    } else {
      setStates([country])
      ville = country
    }
    inscriptionForm.setFieldsValue({ ville })
  }
  
  function handleSubscribe(values) {
    subscribe(values)
  }
  
  return (
    <Spin spinning={loading}>
      <Form {...formItemLayout} size={"large"}
            onFinish={handleSubscribe} form={inscriptionForm}
            style={{ marginBottom: 60 }}>
        <Form.Item
          label={"Nom"}
          name={"nom"}
          rules={[
            { required: true, message: "Veuillez saisir votre nom!" }
          ]}
        >
          <Input autoFocus />
        </Form.Item>
        <Form.Item
          label={"Prénom"}
          name={"prenom"}
          rules={[
            { required: true, message: "Veuillez saisir votre prénom!" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"E-mail"}
          name={"email"}
          rules={[
            {
              type: "email",
              message: "Veuillez saisir un e-mail valide!"
            },
            { required: true, message: "Veuillez saisir votre e-mail!" }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={"Spécialité"}
          name={"specialite"}
          rules={[
            {
              required: true,
              message: "Veuillez indiquer votre spécialité!"
            }
          ]}
        >
          <Cascader
            options={specialite}
            showSearch={{
              matchInputWidth: true,
              filter: (input, option) => {
                return option.length > 1 ?
                  option[1].label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  : option[0].label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            }}
            placeholder={"Recherche"}
            notFoundContent={"Aucun résultat"}
          />
        </Form.Item>
        <Form.Item
          label={"Pays"}
          name={"pays"}
          rules={[
            { required: true, message: "Veuillez indiquer votre pays!" }
          ]}
        >
          <Select
            showSearch
            placeholder={"Selectionnez votre pays"}
            onChange={onChangeCountry}
            options={countries.map((item) => ({
              label: item,
              value: item
            }))}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
        <Form.Item
          label={"Ville"}
          name={"ville"}
          rules={[
            {
              required: true,
              message: "Veuillez indiquer votre ville!"
            }
          ]}
        >
          <Select
            showSearch
            placeholder={"Selectionnez votre ville"}
            options={states.map((item) => ({
              label: item,
              value: item
            }))}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
        <Form.Item wrapperCol={{
          xs: { offset: 0 },
          sm: { offset: 2 },
          md: { offset: 3, span: 18 }
        }}>
          <Checkbox onChange={(e) => setIsChecked(e.target.checked)}>
            J'accepte de
            recevoir des informations médicales des
            firmes pharmaceutiques au cours de cette manifestation
            scientifique
            internationale
          </Checkbox>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Row justify={"center"}>
            <Col>
              <Button
                type="primary"
                disabled={!isChecked}
                htmlType={"submit"}
              >
                S'inscrire
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <Modal visible={isVisible} centered footer={null} closable={false}>
        <Result
          status="success"
          title={"Félicitations " + attendee.nom.toUpperCase() + " " + attendee.prenom + " !"}
          subTitle={"Votre inscription à bien été effectué avec l'adresse" + attendee.email}
          extra={[
            <Button type="primary"
                    onClick={() => {
                      handleLogin(attendee.email)
                      setIsVisible(false)
                    }}
            >
              Connexion
            </Button>
          ]}
        />
      </Modal>
    </Spin>
  )
}