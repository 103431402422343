import React from "react"
import { Col, Row, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"

const { Title } = Typography
export default function InscriptionCover() {
  return (
    <div style={{ backgroundColor: "#72151A" }}>
      <Row
        align={"middle"}
        justify={"space-between"}
      >
        <Col span={8} style={{ padding: 10 }}>
          <StaticImage
            src={"../../images/logoCongres.png"}
            alt={"logo congress"}
            height={107}
          />
        </Col>
        <Col span={8} style={{ paddingRight: 10 }}>
          <StaticImage
            src={"../../images/logoOrganisateur.png"}
            alt={"logo organisateur"}
            height={107}
          />
        </Col>
      </Row>
      <Row justify={"center"}>
        <Col xs={{ span: 24 }} lg={{ span: 24 }}>
          <Title level={2} style={{ color: "white", textAlign: "center" }}>
            Formulaire d'inscription
          </Title>
        </Col>
      </Row>
    </div>
  )
}