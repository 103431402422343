const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 2 },
    md: { span: 3 }
  },
  wrapperCol:{
    xs: { span: 24 },
    sm: { span: 22 },
    md: { span: 18 }
  }
}

export { formItemLayout }