const specialite = [
  {
    value: "Pneumologues",
    label: "Pneumologues"
  },
  {
    value: "Allergologues",
    label: "Allergologues"
  },
  {
    value: "Pédiatres",
    label: "Pédiatres"
  },
  {
    value: "Médecine interne",
    label: "Médecine interne"
  },
  {
    value: "Immunologistes",
    label: "Immunologistes"
  },
  {
    value: "ORL",
    label: "ORL"
  },
  {
    value: "Ophtalmologues",
    label: "Ophtalmologues"
  },
  {
    value: "Médecins du travail",
    label: "Médecins du travail"
  },
  {
    value: "Anesthésiste-réanimateur",
    label: "Anesthésiste-réanimateur"
  },
  {
    value: "Médecin généraliste",
    label: "Médecin généraliste"
  },
  {
    value: "Autre",
    label: "Autre",
    children: [
      {
        value: "Administrateur",
        label: "Administrateur"
      },
      {
        value: "Anatomie Pathologique",
        label: "Anatomie Pathologique"
      },
      {
        value: "Anatomo-Pathologie",
        label: "Anatomo-Pathologie"
      },
      {
        value: "Anesthésiologie-Réanimation",
        label: "Anesthésiologie-Réanimation"
      },
      {
        value: "Biochimie",
        label: "Biochimie"
      },
      {
        value: "Biologie",
        label: "Biologie"
      },
      {
        value: "Biologie Clinique",
        label: "Biologie Clinique"
      },
      {
        value: "Cardiologie",
        label: "Cardiologie"
      },
      {
        value: "Chirurgie Cardiaque",
        label: "Chirurgie Cardiaque"
      },
      {
        value: "Chirurgie Cardio Vasculaire",
        label: "Chirurgie Cardio Vasculaire"
      },
      {
        value: "Chirurgie dentaire",
        label: "Chirurgie dentaire"
      }, {
        value: "Chirurgie générale",
        label: "Chirurgie générale"
      },
      {
        value: "Chirurgie Infantile",
        label: "Chirurgie Infantile"
      },
      {
        value: "Chirurgie Maxillo Faciale",
        label: "Chirurgie Maxillo Faciale"
      },
      {
        value: "Chirurgie Neurologique",
        label: "Chirurgie Neurologique"
      },
      {
        value: "Chirurgie Oncologique",
        label: "Chirurgie Oncologique"
      },
      {
        value: "Chirurgie Orthopédique",
        label: "Chirurgie Orthopédique"
      },
      {
        value: "Chirurgie Pédiatrique",
        label: "Chirurgie Pédiatrique"
      },
      {
        value: "Chirurgie Plastique",
        label: "Chirurgie Plastique"
      },
      {
        value: "Chirurgie thoracique",
        label: "Chirurgie thoracique"
      },
      {
        value: "Chirurgie Urologique",
        label: "Chirurgie Urologique"
      },
      {
        value: "Chirurgie Viscérale",
        label: "Chirurgie Viscérale"
      },
      {
        value: "Cytologie",
        label: "Cytologie"
      },
      {
        value: "Endocrinologie",
        label: "Endocrinologie"
      },
      {
        value: "Epidémiologie",
        label: "Epidémiologie"
      },
      {
        value: "Gastro Entérologie",
        label: "Gastro Entérologie"
      },
      {
        value: "Gynéco Obstetrique",
        label: "Gynéco Obstetrique"
      },
      {
        value: "Gynécologie",
        label: "Gynécologie"
      },
      {
        value: "Hématologie",
        label: "Hématologie"
      },
      {
        value: "Hémobiologie",
        label: "Hémobiologie"
      },
      {
        value: "Infirmière",
        label: "Infirmière"
      },
      {
        value: "Maïeutique",
        label: "Maïeutique"
      },
      {
        value: "Maladies Infectieuses",
        label: "Maladies Infectieuses"
      },
      {
        value: "Médecine du sport",
        label: "Médecine du sport"
      },
      {
        value: "Médecine Esthétique",
        label: "Médecine Esthétique"
      },
      {
        value: "Médecine Légale",
        label: "Médecine Légale"
      },
      {
        value: "Médecine Nucléaire",
        label: "Médecine Nucléaire"
      },
      {
        value: "Microbiologie",
        label: "Microbiologie"
      }, {
        value: "Néphrologie",
        label: "Néphrologie"
      },
      {
        value: "Neuro Chirurgie",
        label: "Neuro Chirurgie"
      },
      {
        value: "Neurologie",
        label: "Neurologie"
      },
      {
        value: "Oncologie",
        label: "Oncologie"
      },
      {
        value: "Oncologie Médicale",
        label: "Oncologie Médicale"
      },
      {
        value: "Oto-Rhino-Laryngologie",
        label: "Oto-Rhino-Laryngologie"
      },
      {
        value: "Parasitologie",
        label: "Parasitologie"
      },
      {
        value: "Pharmacie",
        label: "Pharmacie"
      },
      {
        value: "Pharmacie hospitalière",
        label: "Pharmacie hospitalière"
      },
      {
        value: "Pharmacologie",
        label: "Pharmacologie"
      },
      {
        value: "Physiologie",
        label: "Physiologie"
      },
      {
        value: "Pneumo Phyisiologie",
        label: "Pneumo Phyisiologie"
      },
      {
        value: "Psychiatrie",
        label: "Psychiatrie"
      },
      {
        value: "Radiologie",
        label: "Radiologie"
      },
      {
        value: "Radiothérapie",
        label: "Radiothérapie"
      },
      {
        value: "Réeducation et réadaptation fonctionnelle",
        label: "Réeducation et réadaptation fonctionnelle"
      },
      {
        value: "Rééducation Fonctionnelle",
        label: "Rééducation Fonctionnelle"
      },
      {
        value: "Rhumatologie",
        label: "Rhumatologie"
      },
      {
        value: "Sénologie",
        label: "Sénologie"
      },
      {
        value: "Technicien en Pharmacie",
        label: "Technicien en Pharmacie"
      },
      {
        value: "Urologie",
        label: "Urologie"
      },
      {
        value: "Virologie",
        label: "Virologie"
      }
    ]
  }
]

export { specialite }